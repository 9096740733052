import {isFeatureEnabled} from '@github-ui/feature-flags'
import {sendStats} from '@github-ui/stats'
import {reportError} from '@github-ui/failbot'

export class TrustedTypesPolicyError extends Error {}

interface PolicyParam {
  policy: () => string
  policyName: string
  fallback: string
  fallbackOnError?: boolean
}

function apply({policy, policyName, fallback, fallbackOnError = false}: PolicyParam): string {
  try {
    if (isFeatureEnabled('BYPASS_TRUSTED_TYPES_POLICY_RULES')) return fallback
    return policy()
  } catch (e) {
    if (e instanceof TrustedTypesPolicyError) throw e
    reportError(e as Error)
    sendStats({incrementKey: 'TRUSTED_TYPES_POLICY_ERROR', trustedTypesPolicyName: policyName})
    if (!fallbackOnError) throw e
  }

  return fallback
}

export const trustedTypesPolicy = {apply}
